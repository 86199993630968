import '@popperjs/core'
import 'bootstrap'
import '@fortawesome/fontawesome-free/js/all'

import './styles/app.scss';


import 'material-icons/iconfont/material-icons.css';
import '@fancyapps/fancybox/dist/jquery.fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'

import './jb_bootstrap'

import $ from 'jquery'
import 'selectize'
import 'selectize/dist/css/selectize.css'

$('.jimsoft-selectize').selectize({
    plugins: ["remove_button"],

});

const $checkAll_productGroups =$('#checkAll_productGroups');
$checkAll_productGroups.on('click', function() {
    $('input[name="productGroups[]"]').prop('checked', this.checked);
});


const $generateCatalogForm = $('#generateCatalogForm');

$generateCatalogForm.on('submit', event => {
    if($generateCatalogForm.find('[name="productGroups[]"]:checked').length === 0) {
        event.preventDefault();
        alert('Bitte Artikel auswählen!');
        return false;
    }
});